import styled from 'styled-components';

const LoginStyles = styled.nav`
.login-screen {
    background-color: var(--orange);
    min-height: 87vh;
}

a {
  text-decoration: none;
  color: var(--orange);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Bebas Neue";
}

p, a {
  font-family: "Work Sans";
}

button {
    width: 100%;
    border-radius: 0.3em;
}

.ma-btn {
    background-color: mediumpurple;
    box-shadow: 0 2px 0 purple;
  
    &:hover {
      background-color: var(--purple);
    }
  }

.linkStyle1 {
    padding: 0;
    text-shadow: none;
    border-radius: .3em;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
  
.google-btn {
    background-color: #ea4235;
    box-shadow: 0 2px 0 darkred;
  
    &:hover {
      background-color: #db3236;
    }
}


.fb-btn {
    background-color: #4965a7;
    box-shadow: 0 2px 0 darkblue;
  
    &:hover {
      background-color: #2f5b9d;
    }
}

input {
    width: 100%;
}

.or {
    position: relative;
    width: 100%;
    height: 1px;
    margin: 1rem 0 2rem 0;
    background-color: #e6ecf5; 
}

.or:after {
    content: 'OR';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 0 25px;
    font-size: 10px;
    z-index: 5; 
}
  
.bg-white {
    background-color: #fff !important;
    margin: auto;
    width: 30%;
    border-radius: 0.5em;
}

.registration-login-form {
    background-color: #fff !important;
    border-radius: 0.5em;
}

.registration-content {
    padding: 10px;
}

.upload-book-cover-box {
    border: 1px solid #e6ecf5;
    position: relative;
    height: 150px;
    width: 150px;
    border-radius: 80px;
    overflow: hidden;
    margin: auto;
    margin-top: -50px;
  }
  
  
  .uploaded-book-cover {
    max-height: 150px;
    overflow: hidden;
    cursor: pointer;
  }
  
  .upload-book-cover-box:hover {
    filter: blur(1px);
  }
  
  .upload-book-cover-box input {
    display: none;
  }
  
  .upload-book-cover-caption {
    font-weight: 500;
    text-align: center;
    top: 130px;
    padding: 5px;
    position: absolute;
    opacity: 0.5;
  }

  
`;
export default LoginStyles;

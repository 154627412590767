import React from 'react'
import Layout from "../components/Layout"
import Loginstyles from '../styles/LoginStyles';
import { Container, Row } from "reactstrap";
import { fetchUrl } from '../apiClient/baseApi';
import { graphql } from 'gatsby';
import { useTranslation} from 'gatsby-plugin-react-i18next';


const toastr = require('toastr');


export default function ChangePassword () {
    const { t } = useTranslation();

    const [resettoken, setResettoken] = React.useState('');
    React.useEffect(() => {
      if(typeof window !==`undefined`){
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString).get('token');
        if(urlParams){
            setResettoken(urlParams);
        }
      }
    }, []);

    const pwdCheck = () => {
        if (document.getElementById('password').value ===
        document.getElementById('confirm_password').value) {
        document.getElementById('message').style.color = 'green';
        document.getElementById('message').innerHTML = 'Password matching';
      } else {
        document.getElementById('message').style.color = 'red';
        document.getElementById('message').innerHTML = 'Password not matching';
      }
    }

    const handleSubmit = async function() {
        if (resettoken) {
            let data = { "token": resettoken, "password" : document.getElementById("password").value}
            await fetchUrl('post', '/auth/password/reset', data).then(() => {
                toastr.success('Done, kindly login with your new password')
            })
        }
    }

  return (
  <Layout>
    <Loginstyles>
    <div className="login-screen siteStyl">
    <br />
    <Container className="bg-white" style={{width: "40%", padding: "50px"}}>
        <Row>
            <h4> {t('logon25')} </h4>
            <div style={{padding: "15px"}}>
                <input placeholder={t('logon11')} type="password" id="password" required />
            </div>
            <div style={{padding: "15px"}}>
                <input placeholder={t('logon12')} type="password" id="confirm_password" required onKeyUp={() => pwdCheck()} />
                <span id='message'></span>
            </div>
            <div style={{padding: "15px"}}>
                <button className="ma-btn" onClick={handleSubmit}>{t('logon23')}</button>
            </div>
        </Row>
    </Container>
    </div>
    <br />

    </Loginstyles>
  </Layout> 
   )
  }

  export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["logon", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;